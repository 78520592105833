import test from "@/config/test.json";
import local from "@/config/local.json";
import delta from "@/config/qa-delta.json";
import oscar from "@/config/qa-oscar.json";
import sierra from "@/config/qa-sierra.json";
import production from "@/config/production.json";
import development from "@/config/development.json";
import productionEcho from "@/config/production-echo.json";

const getEnvironmentUrl = (urlKey, appendString = "") => {
  let apiEnv = "";

  switch (process.env.VUE_APP_ENV) {
    case "prod":
      apiEnv = production[urlKey];
      break;

    case "prod-echo":
      apiEnv = productionEcho[urlKey];
      break;

    case "dev":
      apiEnv = development[urlKey];
      break;

    case "test":
      apiEnv = test[urlKey];
      break;

    case "qa-delta":
      apiEnv = delta[urlKey];
      break;

    case "qa-sierra":
      apiEnv = sierra[urlKey];
      break;

    case "qa-oscar":
      apiEnv = oscar[urlKey];
      break;

    case "local":
      apiEnv = local[urlKey];
      break;
    default:
  }

  return `${apiEnv}/${appendString}`;
};

export const getBaseUrl = () => getEnvironmentUrl("baseUrl", "order_mgmt/olympus_app/");

export const getOrderSummaryBaseUrl = () => getEnvironmentUrl("orderSummaryUrl");

export const getAuditEventBaseUrl = () => getEnvironmentUrl("auditEventUrl");
