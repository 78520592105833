import pagesURL from "./routes";

import LoginCallback from "./LoginCallback.vue";

const AuditEventOrders = () =>
  import(/* webpackChunkName: "AuditEventOrders" */ "@/pages/AuditEvent/Orders.vue");
const AuditEventOrderEvents = () =>
  import(/* webpackChunkName: "AuditEventOrderEvents" */ "@/pages/AuditEvent/OrderEvents.vue");

const NotAllowed = () => import(/* webpackChunkName: "NotAllowed" */ "@/pages/NotAllowed");
const SessionExpired = () =>
  import(/* webpackChunkName: "SessionExpired" */ "@/pages/SessionExpired");
const ServiceDown = () => import(/* webpackChunkName: "ServiceDown" */ "@/pages/ServiceDown");
const PlannedOutage = () => import(/* webpackChunkName: "PlannedOutage" */ "@/pages/PlannedOutage");
const StubPageControls = () =>
  import(/* webpackChunkName: "StubPageControls" */ "@/pages/StubPageControls/StubPageControls");

const SOCEditForm = () =>
  import(/* webpackChunkName: "SOCEditForm" */ "@/pages/MyOrders/SOCEditForm");
const MyApprovals = () =>
  import(/* webpackChunkName: "MyApprovals" */ "@/pages/MyApprovals/MyApprovals");
const SOCApprovalForm = () =>
  import(/* webpackChunkName: "SOCApprovalForm" */ "@/pages/MyApprovals/SOCApprovalForm");
const ApprovalsOverview = () =>
  import(/* webpackChunkName: "ApprovalsOverview" */ "@/pages/MyApprovals/ApprovalsOverview");
const Resources = () => import(/* webpackChunkName: "Resources" */ "@/pages/Resources/Resources");
const Availability = () =>
  import(/* webpackChunkName: "Availability" */ "@/pages/Availability/Availability");

const routes = [
  {
    path: pagesURL.ROOT,
    name: "AuditEventOrders",
    meta: {
      requiresAuth: true,
      hasSearch: true,
    },
    component: AuditEventOrders,
  },
  {
    path: pagesURL.CALLBACK,
    name: "Callback",
    component: LoginCallback,
  },
  {
    path: pagesURL.NOT_ALLOWED,
    name: "NotAllowed",
    component: NotAllowed,
    props: true,
  },
  {
    path: pagesURL.SESSION_EXPIRED,
    name: "SessionExpired",
    meta: { requiresUnauth: true },
    component: SessionExpired,
  },
  {
    path: pagesURL.SERVICE_DOWN,
    name: "ServiceDown",
    meta: { isMaintenance: true, requiresAuth: true },
    component: ServiceDown,
  },
  {
    path: pagesURL.PLANNED_OUTAGE,
    name: "PlannedOutage",
    meta: { isMaintenance: true, requiresAuth: true },
    component: PlannedOutage,
  },
  {
    path: pagesURL.STUB_PAGE_CONTROLS,
    name: "StubPageControls",
    meta: { isMaintenance: true, requiresAuth: true },
    component: StubPageControls,
  },
  {
    path: pagesURL.AUDIT_EVENT.BASE,
    meta: { requiresAuth: true },
    children: [
      {
        path: pagesURL.AUDIT_EVENT.ORDERS,
        name: "AuditEventOrders",
        meta: {
          requiresAuth: true,
          hasSearch: false,
        },
        component: AuditEventOrders,
      },
      {
        path: `${pagesURL.AUDIT_EVENT.EVENTS}/:orderId`,
        name: "AuditEventOrderEvents",
        meta: { requiresAuth: true },
        component: AuditEventOrderEvents,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "AuditEventOrders" },
  },
];

export default routes;
