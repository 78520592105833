import { markRaw } from "vue";
import { ToggleButtonGroup, Select, TextField } from "@nike/now.podium-vue";

import BaseDatepicker from "@/components/ui/BaseDatepicker";

import { translateModule } from "@/utils/string/translate";

import { FIELDS_ENUM } from "@/enums/language/fieldsEnum";

import { FormField, MaterialClassificationResults, FormInputResults } from "./types";

const translateFields = translateModule("fields");

export const FIELD_KEYS = {
  START_DATE: "startTime",
  END_DATE: "endTime",
  ORDER_TYPE: "orderType",
  PO_TYPE: "poType",
  ORDER_ID: "id",
  PO_NUMBER: "poNumbers",
  SALES_ORG: "salesOrg",
} as const;

export const FILTERS_CATEGORIES = [
  { name: "Order ID", id: "orderId" },
  { name: "PO Number", id: "poNumber" },
];

export const REGION_OPTIONS = [
  { value: "GC", text: "MAGC" },
  // { value: "NA", text: "MANA" },
];

export const VAS_VALUES = {
  WITH_WAS: "With Vas",
  NO_VAS: "No Vas",
};

export const LINE_DC_BYPASS_ELIGIBILITY_VALUES = {
  ELIGIBLE: "Eligible",
  NOT_ELIGIBLE: "Not Eligible",
};

const optionsGetterForMaterialClassification = (results: MaterialClassificationResults) => {
  return results.map(({ code, description }) => ({
    value: code,
    text: `${code} - ${description}`,
  }));
};

const optionsGetterForFormInputs = (results: FormInputResults) => {
  return results.map(({ id, alias }) => ({ value: id, text: alias }));
};

const headerFields: Array<FormField> = [
  {
    key: FIELD_KEYS.ORDER_ID,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.orderUuid),
    type: "orderId",
  },
  {
    key: FIELD_KEYS.PO_NUMBER,
    component: markRaw(TextField),
    label: translateFields(FIELDS_ENUM.poNumber),
    type: "poNumber",
  },
  {
    key: FIELD_KEYS.START_DATE,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.startDate),
    isRangeDatepicker: false,
    type: "poNumber",
  },
  {
    key: FIELD_KEYS.END_DATE,
    component: markRaw(BaseDatepicker),
    label: translateFields(FIELDS_ENUM.endDate),
    isRangeDatepicker: false,
    type: "poNumber",
  },
];

export const FIELDS = [...headerFields];
