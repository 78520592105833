import auditEventRepository from "@/api/repositories/auditEvent";
import orderSummaryRepository from "@/api/repositories/orderSummary";

import { AUDIT_EVENT } from "@/api/endpoints";

export default {
  fetchOrders: (filters) => {
    // orderSummaryRepository.get(
    //   `osd/api/v5/orders?filter=receivedDateAfter(2024-05-01T00:00:00.924Z)&fields=id,customerPurchaseOrderNumber,status,sapOrderNumber,customerBusinessTypeCode,launchCode,leagueIndicator,modificationDate&filter=status(Submitted)&count=100&filter=salesOrderType(ZSTA)`
    // ),
    return orderSummaryRepository.get(`${AUDIT_EVENT.ORDER_SUMMARY}`, { params: filters });
  },

  fetchOrderEvent: (orderId, filters) =>
    auditEventRepository.get(`${AUDIT_EVENT.ORDER_EVENTS}/${orderId}`, { params: filters }),
};
